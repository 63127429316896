<template>
  <div class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <!-- <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="
              filter.sort_type == 'asc'
                ? (filter.sort_type = 'desc')
                : (filter.sort_type = 'asc')
            "
          /> -->
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input v-model="filter.search" placeholder="Cari nama produk" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-1 mt-5" v-if="isLoading == true">
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table v-else class="bg-white" max-height="67.5vh" :columns="columns" :rows="rows.data"
      :fixed-header="false" :sort-options="{
        enabled: false,
      }" :pagination-options="{
        enabled: false,
      }" :select-options="{
        enabled: true,
      }" @on-selected-rows-change="selectionChanged">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />

        <span v-else-if="props.column.field == 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{ props.column.label }}
          </span>
        </span>

        <span v-else-if="props.column.field == 'formatted_id'" class="d-flex align-items-center"
          style="gap: 12px; cursor: pointer" @click="() => {
              filter.sort_by = null;
              filter.sort_type == 'desc'
                ? (filter.sort_type = 'asc')
                : (filter.sort_type = 'desc');
            }
            ">
          <span>
            {{ props.column.label }}
          </span>
          <b-img v-if="filter.sort_by == null" class="filter-image" style="width: 12px"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button" />
        </span>

        <span v-else-if="props.column.field == 'alias_name'" class="d-flex align-items-center"
          style="gap: 12px; cursor: pointer" @click="() => {
              filter.sort_by = 'alias_name';
              filter.sort_type == 'desc'
                ? (filter.sort_type = 'asc')
                : (filter.sort_type = 'desc');
            }
            ">
          <span>
            {{ props.column.label }}
          </span>
          <b-img v-if="filter.sort_by == 'alias_name'" class="filter-image" style="width: 12px"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button" />
        </span>

        <span v-else>
          <span>
            {{ props.column.label }}
          </span>
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'standard_price'">
          <span>
            {{ props.row.standard_price | formatAmount }}
          </span>
        </span>

        <!-- <span v-else-if="props.column.field === 'sku_code'">
          <span>
            {{ props.row.sku_code || '-' }}
          </span>
        </span> -->
        <span v-else-if="props.column.field === 'warehouse_names'">
          <span>
            {{ props.row.warehouse_names.join(", ") || "-" }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'minimum_price'">
          <span>
            {{ props.row.minimum_price | formatAmount }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{ props.row.avg_purchase_price | formatAmount }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="
            checkPermission('update produk') |
            checkPermission('delete produk')
          ">
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
              </template>
              <b-dropdown-item @click="
                $router.push({
                  name: 'master-product-eceran.detail',
                  params: { id: props.row.uuid },
                })
                " v-if="checkPermission('update produk')">
                <span> Edit </span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(props.row.uuid)" v-if="checkPermission('delete produk')">
                <span> Hapus </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border" style="padding: 0.8rem">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows" :limit="4" align="right" @pagination-change-page="getDataProduct" />
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- <ModalIncomeProduct /> -->
     <!-- Preview Print Barcode -->
    <b-modal id="form-export-retail" size="lg" title="Preview Print">
      <vue-html2pdf :enable-download="true" :preview-modal="true" ref="html2Pdf" :float-layout="false"
        :manual-pagination="true" filename="export-barcode" :pdf-quality="2" :html-to-pdf-options="{
          html2canvas: {
            scale: 4,
            letterRendering: true,
            useCORS: true
          },
          jsPDF: {
            unit: 'mm',
            format: paperSizes[selectedOptionLabel],
            orientation: 'landscape'
          }
        }">
        <section slot="pdf-content" id="contentToPrint" ref="contentToPrint">
          <!-- style="padding-top: 2mm; padding-left: 2mm;gap: 2mm;max-width: 46mm" -->
          <div class="d-flex flex-wrap" :class="{ 'flex-column': selectedOptionLabel === '1' }"
            :style="labelCardStyle[selectedOptionLabel]">
            <!-- <div v-for="item, index in barcode_numbers" :key="index" :style="styleLabel[selectedOptionLabel]"> -->
            <!-- + selectedOptionLabel === '1' ? 'padding-left: 1mm' : ''" -->
            <div v-for="item, index in barcode_numbers" :key="index"
              :style="`${styleLabel[selectedOptionLabel]};${selectedOptionLabel === '1' ? 'padding-left: 1mm' : selectedOptionLabel === '2' && index % 2 ? 'padding-left: 1.5mm' : ''}`">
              <div class="text-center">
                <h1
                  :style="`font-size: ${textLabelStyle[selectedOptionLabel]};color: black;margin-bottom: 0px;padding-top:2mm;font-weight: 900`">
                  {{ item.name }}</h1>
                <!-- <b-img :src="`https://barcodeapi.org/api/${item.barcode_number}`" /> -->
                <vue-barcode :value="item.barcode_number" element-tag="img" :text="`${item.barcode_number}`"
                  :class="`label-${selectedOptionLabel}-line`">
                  Render failed
                </vue-barcode>
              </div>
              <div class="html2pdf__page-break" v-if="(index + 1) % pageBreakLabel[selectedOptionLabel] === 0" />
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="generateReport">
          Download Barcode
        </b-button>
      </template>
    </b-modal>

    <!-- Barcode QTY -->
    <b-modal id="modal-barcode-qty-retail" size="lg" title="Jumlah Barcode">
      <div class="p-1">
        <label for="qty">Barcode Qty</label>
        <b-form-spinbutton id="qty" v-model="barcode_qty" min="1" />
      </div>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="handleBarcodeQty">
          Go To Download Barcode
        </b-button>
      </template>
    </b-modal>

    <!-- Setting Layout Print -->
    <b-modal id="modal-setting-layout-print-retail" size="lg" title="Setting Layout Print">
      <div class="p-1">
        <label for="label">Ukuran Label</label>
        <b-form-select v-model="selectedOptionLabel" :options="optionLabels" id="label" />
      </div>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="handlePrintBarcode">
          Next
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BSpinner,
  BButton,
  BFormSpinbutton
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import { mapActions } from "vuex";

import { VueGoodTable } from "vue-good-table";

import VueHtml2pdf from 'vue-html2pdf'
import VueBarcode from 'vue-barcode'
import SearchWithScanner from '@/components/Search/SearchWithScanner.vue'


const { hostname, protocol, port } = window.location

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BRow,
    BCol,
    BSpinner,
    VueHtml2pdf,
    VueBarcode,
    SearchWithScanner,
    BButton,
    BFormSpinbutton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "ID",
          field: "formatted_id",
        },
        {
          label: 'Produk Induk',
          field: 'parent_product.name',
        },
        {
          label: "Nama Alias",
          field: "alias_name",
        },
        {
          label: "Produk Induk",
          field: "parent_product.name",
        },
        {
          label: "Lokasi SKU",
          field: "warehouse_names",
        },
        {
          label: "Nilai Konversi",
          field: "retail_convertion",
        },
        {
          label: "Harga Modal",
          field: "avg_purchase_price",
        },
        {
          label: "Harga Standar",
          field: "standard_price",
        },
        {
          label: "Harga Minimum",
          field: "minimum_price",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      formPayload: {
        product_type: "",
        image: "",
        item_uuid: "",
        specification: "",
        uom_uuid: "",
        alias_name: "",
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: "",
        discount_end_date: "",
        warehouses_uuid: [],
      },
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
        sort_by: null,
      },
      uuids: [],
      barcode_numbers: [],
      barcode_qty: 1,
      paperSizes: {
        '1': [30, 19],
        '1x50': [50, 30],
        '2': [70, 19],
      },
      selectedOptionLabel: null,
      optionLabels: [
        { value: null, text: 'Please select an option' },
        { value: '1', text: 'Label 1 Line (30mm x 19mm)' },
        { value: '1x50', text: 'Label 1 Line (50mm x 30mm)' },
        { value: '2', text: 'Label 2 Line (33mm x 19mm)' },
      ],
      styleLabel: {
        '1': 'width: 30mm; height: 19mm;',
        '1x50': 'max-width: 50mm; max-height: 30mm;',
        '2': 'width: 34mm; height: 19mm;',
      },
      pageBreakLabel: {
        '1': 1,
        '1x50': 1,
        '2': 1,
      },
      textLabelStyle: {
        '1': '4.5px',
        '1x50': '7px',
        '2': '4.5px',
      },
      labelCardStyle: {
        '1': 'max-width: 30mm;padding-top: 0; padding-left: 0;gap: 0;',
        '1x50': 'max-width: 50mm;padding-top: 0; padding-left: 0;gap: 0;',
        '2': 'width: 70mm;margin-left: 2mm;padding-left: 1mm;', // padding-top: 2mm; padding-left: 2mm;gap: 2mm;
      }
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getDataProduct();
      }, 300),
      deep: true,
    },
    uuids: {
      handler(uuids) {
        this.barcode_numbers = []
        uuids.forEach(uuid => {
          if (uuid) {
            const item = this.rows.data.find(item => item.uuid === uuid);
            if (item && item.barcode_number) {
              // Check if the barcode number is already in the array
              const exists = this.barcode_numbers.some(
                existing => existing.barcode_number === item.barcode_number
              );

              if (!exists) {
                this.barcode_numbers.push({
                  barcode_number: item.barcode_number,
                  name: item.name
                });
              }
            }
          }
        });
      },
      deep: true,
    }
  },
  created() {
    this.getDataProduct();
  },
  methods: {
    ...mapActions("parentProduct", ["getData"]),
    printLabel() {

      const contentHtml = this.$refs.contentToPrint.innerHTML;
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Label</title>
            <style>
            @media print {
                @page {
                  size: ${this.paperSizes[this.selectedOptionLabel]};
                }
              }
              .label-1x50-line .vue-barcode-element {
                width: 50mm;
                height: 25mm;
              }
              .label-1-line .vue-barcode-element {
                width: 33mm;
                height: 10mm;
              }
              .label-2-line .vue-barcode-element {
                width: 33mm;
              }
              .label-3-line .vue-barcode-element {
                width: 22mm;
                height: 13mm;
                object-fit: cover;
              }
            </style>
            <link href="${protocol}//${hostname}${port !== "" ? `:${port}` : ""}/bootstrap.css" rel="stylesheet" />
          </head>
          <body onload="window.print()">
            ${contentHtml}
          </body>
        </html>
      `);
      printWindow.document.close();
    },
    printElement(elementId) {
      // // Get the content of the specified element
      // const content = document.getElementById(elementId).innerHTML;

      // // Create a new window for printing
      // const printWindow = window.open('', '', 'height=600,width=800');

      // // Write the content to the new window
      // printWindow.document.write('<html><head><title>Print Content</title>');
      // printWindow.document.write('<style>body{font-family: Arial, sans-serif;}</style>'); // Optional: add styles
      // printWindow.document.write('</head><body>');
      // printWindow.document.write(content);
      // printWindow.document.write('</body></html>');

      // // Close the document to finish loading the content
      // printWindow.document.close();

      // // Print the content
      // printWindow.print();

      // const element = this.$refs.contentToPrint;
      // const options = {
      //   margin: 1,
      //   filename: 'custom-size.pdf',
      //   image: { type: 'jpeg', quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: 'in', format: [8.5, 11], orientation: 'portrait' }, // Custom paper size [width, height]
      // };
      // html2pdf().from(element).set(options).save();
    },
    handlePrintBarcode() {
      this.$bvModal.hide('modal-setting-layout-print-retail')
      if (this.uuids.length === 1) {
        this.$bvModal.show('modal-barcode-qty-retail')
      } else {
        this.$bvModal.show('form-export-retail')
      }
    },
    openModalLayoutSetting() {
      if (this.uuids.length === 0) {
        errorNotification(this, 'Oops!', 'Silahkan pilih item terlebih dahulu.')
        return;
      }
      this.$bvModal.show('modal-setting-layout-print-retail')
    },
    selectionChanged(event) {
      this.uuids = event.selectedRows.map(item => item.uuid)
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    handleBarcodeQty() {
      this.barcode_numbers = []
      if (this.uuids[0]) {
        const item = this.rows.data.find(item => item.uuid === this.uuids[0]);
        for (let index = 0; index < this.barcode_qty; index++) {
          this.barcode_numbers.push({
            barcode_number: item.barcode_number,
            name: item.name
          })
        }
      }
      if (this.barcode_numbers[0].barcode_number !== null) {
        this.$bvModal.hide('modal-barcode-qty-retail')
        this.$bvModal.show('form-export-retail')
      } else {
        errorNotification(this, 'Oops!', 'Barcode belum tersedia.')
        this.$bvModal.hide('modal-barcode-qty-retail')
      }
    },
    getDataProduct(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      queryParams.product_type = "retail";
      this.getData({ params: queryParams, uuid: "" })
        .then((result) => {
          this.rows = result.data.data;
          this.isLoading = false;
          this.uuid = null;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    clearItem() {
      this.$store.commit("parentProduct/setEditId", null);
      this.$store.commit(
        "parentProduct/setFormPayload",
        (this.formPayload = {
          product_type: "",
          image: "",
          item_uuid: "",
          specification: "",
          uom_uuid: "",
          alias_name: "",
          sku_code: 0,
          standard_price: 0,
          minimum_price: 0,
          discount_percents: [],
          discount_fixed: 0,
          discount_start_date: "",
          discount_end_date: "",
          warehouses_uuid: [],
        })
      );
    },
    detailItem(item) {
      this.$bvModal.show("modal-customer");
      this.isEdit = false;
      this.$store.commit("parentProduct/setResult", item);
    },
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin untuk menghapus produk eceran ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("parentProduct/deleteData", `/${uuid}`)
            .then(() => {
              this.getDataProduct();
              this.$swal({
                icon: "success",
                title: "Sukses!",
                text: "Produk induk berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    async editItem(item) {
      await this.clearItem();
      this.uuid = item.uuid;
      this.$store.commit("parentProduct/setEditId", item.uuid);
      this.$store.commit(
        "parentProduct/setFormPayload",
        (this.formPayload = {
          product_type: "parent",
          brand_uuid: item.brand_uuid,
          item_uuid: item.item.uuid,
          specification: item.specification,
          uom_uuid: item.uom.uuid,
          alias_name: item.alias_name,
          sku_code: item.sku_code,
          standard_price: item.standard_price,
          minimum_price: item.minimum_price,
          discount_percents: item.discount_percents.map((o) => o),
          discount_fixed: item.discount_fixed,
          discount_start_date: item.discount_start_date,
          discount_end_date: item.discount_end_date,
          // warehouses_uuid: [],
        })
      );
      this.$bvModal.show("modal-add-product-parent");
    },
  },
};
</script>

<style lang="scss"></style>
